import $ from 'jquery';

/**
 * Partial collapse of the content and full collapse of any child content
 *
 * @param {jQuery} $trigger jQuery element that triggers content toggle
 * @param {jQuery} $element jQuery element that contains the content
 */
function hide($trigger, $element) {
    const partialHeight = $element.data('partial-height');
    $element
        .height($element[0].offsetHeight)
        .removeClass('collapse-partial in')
        .attr('aria-expanded', false)
        .addClass('collapsing');
    $trigger.addClass('collapsed').attr('aria-expanded', false);

    const $childTrigger = $element.find('[data-toggle="collapse"]');
    if ($childTrigger.length > 0) {
        const $childElement = $($childTrigger.data('target'));
        $childElement.collapse('hide');
    }

    const sectionName = $element.data('section-name');
    if (sectionName) {
        $trigger.attr('aria-label', `show more ${sectionName}`);
    }

    $element.height(partialHeight);
    $element.one('transitionend', () => {
        $element.removeClass('collapsing').addClass('collapse-partial').height('').trigger('hidden.bs.collapse');
    });
}

/**
 * Expand to show full contents
 *
 * @param {jQuery} $trigger jQuery element that triggers content toggle
 * @param {jQuery} $element jQuery element that contains the content
 */
function show($trigger, $element) {
    const partialHeight = $element[0].offsetHeight;
    $element
        .height(partialHeight)
        .data('partial-height', partialHeight)
        .removeClass('collapse-partial')
        .attr('aria-expanded', true)
        .addClass('collapsing');
    $trigger.removeClass('collapsed').attr('aria-expanded', true);

    const sectionName = $element.data('section-name');
    if (sectionName) {
        $trigger.attr('aria-label', `show less ${sectionName}`);
    }

    $element.height($element[0].scrollHeight);
    $element.one('transitionend', () => {
        $element.removeClass('collapsing').addClass('collapse-partial in').height('').trigger('shown.bs.collapse');
    });
}

/**
 * Toggles the showing / partial hiding of content. Can be triggered similar to bootstrap collapse by passing in a
 * trigger element, or by passing in a string that is a selector for the target element.
 *
 * @param {Element|string} target Button that toggles the content section, or string for target element selector
 */
export function partialToggle(target) {
    const toggleSelector = typeof target === 'string' ? target : target.dataset.target;
    const $element = $(toggleSelector);
    const $trigger = $(`[data-toggle="collapse-partial"][data-target="${toggleSelector}"]`);
    const isOpen = $element.hasClass('in');
    if (!$element.hasClass('collapsing')) {
        if (isOpen) {
            hide($trigger, $element);
        } else {
            show($trigger, $element);
        }
    }
}

/**
 * Handles the toggling of the description visibility + link text and label
 *
 * @param {Event} e The click event
 */
export function toggleDescription(e) {
    const $element = $(e.currentTarget);

    e.preventDefault();

    const isExpand = $element.data('text-expand') === $element.text();
    $('.js-description-condensed').toggleClass('hidden', isExpand);
    $('.js-description-full').toggleClass('hidden', !isExpand);
    $element.attr('aria-label', `show ${isExpand ? 'less' : 'more'} description`);
    $element.text($element.data(isExpand ? 'text-contract' : 'text-expand'));
}
